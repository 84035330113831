var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-filter box1" }, [
    _c("div", { staticClass: "form mb-0", attrs: { action: "#" } }, [
      _c("h4", [_vm._v("Filter By")]),
      _c("div", { staticClass: "filter-options price-filter" }, [
        _c("h6", [_vm._v("Price")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.price,
              expression: "price"
            }
          ],
          staticClass: "form-control-range mb-3",
          attrs: {
            type: "range",
            min: _vm.minPrice,
            max: _vm.maxPrice,
            "data-toggle": "tooltip",
            "data-placement": "top",
            title: _vm.price
          },
          domProps: { value: _vm.price },
          on: {
            change: _vm.priceChanged,
            __r: function($event) {
              _vm.price = $event.target.value
            }
          }
        }),
        _c("ul", { staticClass: "list" }, [
          _c("li", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.minPrice,
                  expression: "minPrice"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.minPrice },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.minPrice = $event.target.value
                }
              }
            })
          ]),
          _vm._m(0),
          _c("li", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.price,
                  expression: "price"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.price },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.price = $event.target.value
                }
              }
            })
          ])
        ])
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#height-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "height-filter"
            }
          },
          [_vm._v(" Height of the product (mm) ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "height-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.heights,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select heights",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.heightChanged },
              model: {
                value: _vm.height,
                callback: function($$v) {
                  _vm.height = $$v
                },
                expression: "height"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#width-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "width-filter"
            }
          },
          [_vm._v(" Width of the product (mm) ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "width-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.widths,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select widths",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.widthChanged },
              model: {
                value: _vm.width,
                callback: function($$v) {
                  _vm.width = $$v
                },
                expression: "width"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#depth-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "depth-filter"
            }
          },
          [_vm._v(" Depth (cm) ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "depth-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.depths,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select depths",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.depthChanged },
              model: {
                value: _vm.depth,
                callback: function($$v) {
                  _vm.depth = $$v
                },
                expression: "depth"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#feature-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "feature-filter"
            }
          },
          [_vm._v(" Features ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "feature-filter" }
          },
          [
            _c("ul", { staticClass: "list" }, [
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.supercooling,
                        expression: "supercooling"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-1" },
                    domProps: {
                      checked: Array.isArray(_vm.supercooling)
                        ? _vm._i(_vm.supercooling, null) > -1
                        : _vm.supercooling
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.supercooling,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.supercooling = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.supercooling = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.supercooling = $$c
                          }
                        },
                        _vm.supercoolingChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-1" } }, [
                    _vm._v("SuperCooling")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.vitaFresh,
                        expression: "vitaFresh"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-2" },
                    domProps: {
                      checked: Array.isArray(_vm.vitaFresh)
                        ? _vm._i(_vm.vitaFresh, null) > -1
                        : _vm.vitaFresh
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.vitaFresh,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.vitaFresh = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.vitaFresh = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.vitaFresh = $$c
                          }
                        },
                        _vm.vitaFreshChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-2" } }, [
                    _vm._v("VitaFresh")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.vitaFreshPlus,
                        expression: "vitaFreshPlus"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-3" },
                    domProps: {
                      checked: Array.isArray(_vm.vitaFreshPlus)
                        ? _vm._i(_vm.vitaFreshPlus, null) > -1
                        : _vm.vitaFreshPlus
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.vitaFreshPlus,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.vitaFreshPlus = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.vitaFreshPlus = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.vitaFreshPlus = $$c
                          }
                        },
                        _vm.vitaFreshPlusChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-3" } }, [
                    _vm._v("VitaFresh Plus")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.vitaFreshPro,
                        expression: "vitaFreshPro"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-4" },
                    domProps: {
                      checked: Array.isArray(_vm.vitaFreshPro)
                        ? _vm._i(_vm.vitaFreshPro, null) > -1
                        : _vm.vitaFreshPro
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.vitaFreshPro,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.vitaFreshPro = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.vitaFreshPro = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.vitaFreshPro = $$c
                          }
                        },
                        _vm.vitaFreshProChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-4" } }, [
                    _vm._v("VitaFresh Pro")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.interiorLight,
                        expression: "interiorLight"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-5" },
                    domProps: {
                      checked: Array.isArray(_vm.interiorLight)
                        ? _vm._i(_vm.interiorLight, null) > -1
                        : _vm.interiorLight
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.interiorLight,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.interiorLight = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.interiorLight = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.interiorLight = $$c
                          }
                        },
                        _vm.interiorLightChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-5" } }, [
                    _vm._v("Interior light")
                  ])
                ])
              ])
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#series-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "series-filter"
            }
          },
          [_vm._v(" Product series ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "series-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.serieses,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select serieses",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.seriesChanged },
              model: {
                value: _vm.series,
                callback: function($$v) {
                  _vm.series = $$v
                },
                expression: "series"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#connectivity-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "connectivity-filter"
            }
          },
          [_vm._v(" Connectivity type ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "connectivity-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.connectivities,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select connectivity types",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.connectivityChanged },
              model: {
                value: _vm.connectivity,
                callback: function($$v) {
                  _vm.connectivity = $$v
                },
                expression: "connectivity"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#hinge-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "hinge-filter"
            }
          },
          [_vm._v(" Door hinge ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "hinge-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.doorHinges,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select door hinges",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.doorHingeChanged },
              model: {
                value: _vm.doorHinge,
                callback: function($$v) {
                  _vm.doorHinge = $$v
                },
                expression: "doorHinge"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#accessory-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "type-filter"
            }
          },
          [_vm._v(" Included accessories ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "accessory-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.accessories,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select accessories",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.accessoryChanged },
              model: {
                value: _vm.accessory,
                callback: function($$v) {
                  _vm.accessory = $$v
                },
                expression: "accessory"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#type-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "type-filter"
            }
          },
          [_vm._v(" Product Type ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "type-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.types,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select types",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.typeChanged },
              model: {
                value: _vm.type,
                callback: function($$v) {
                  _vm.type = $$v
                },
                expression: "type"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#control-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "type-filter"
            }
          },
          [_vm._v(" Type of control ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "control-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.controlTypes,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select control types",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.controlTypeChanged },
              model: {
                value: _vm.controlType,
                callback: function($$v) {
                  _vm.controlType = $$v
                },
                expression: "controlType"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#noise-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "noise-filter"
            }
          },
          [_vm._v(" Noise ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "noise-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.noiseLevels,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select noises",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.noiseChanged },
              model: {
                value: _vm.noise,
                callback: function($$v) {
                  _vm.noise = $$v
                },
                expression: "noise"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#efficiency-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "efficiency-filter"
            }
          },
          [_vm._v(" Energy Efficiency ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "efficiency-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.energyEfficiencies,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select energy efficiencies",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.energyEfficiencyChanged },
              model: {
                value: _vm.energyEfficiency,
                callback: function($$v) {
                  _vm.energyEfficiency = $$v
                },
                expression: "energyEfficiency"
              }
            })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "filter-options dropdown" }, [
      _c(
        "h6",
        {
          staticClass: "heading",
          attrs: {
            "data-toggle": "collapse",
            "data-target": "#installation-filter",
            role: "button",
            "aria-expanded": "true",
            "aria-controls": "installation-filter"
          }
        },
        [_vm._v(" Installation Type ")]
      ),
      _c(
        "div",
        {
          staticClass: "wrapper collapse show",
          attrs: { id: "installation-filter" }
        },
        [
          _c("VueMultiselect", {
            attrs: {
              options: _vm.installations,
              multiple: true,
              "close-on-select": true,
              "open-direction": "bottom",
              placeholder: "Select installation types",
              searchable: false,
              selectLabel: "",
              deselectLabel: ""
            },
            on: { input: _vm.installationChanged },
            model: {
              value: _vm.installation,
              callback: function($$v) {
                _vm.installation = $$v
              },
              expression: "installation"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "filter-options dropdown" }, [
      _c(
        "h6",
        {
          staticClass: "heading",
          attrs: {
            "data-toggle": "collapse",
            "data-target": "#feet-filter",
            role: "button",
            "aria-expanded": "true",
            "aria-controls": "feet-filter"
          }
        },
        [_vm._v(" Adjustable feet ")]
      ),
      _c(
        "div",
        { staticClass: "wrapper collapse show", attrs: { id: "feet-filter" } },
        [
          _c("VueMultiselect", {
            attrs: {
              options: _vm.adjustableFeets,
              multiple: true,
              "close-on-select": true,
              "open-direction": "bottom",
              placeholder: "Select adjustable feets",
              searchable: false,
              selectLabel: "",
              deselectLabel: ""
            },
            on: { input: _vm.adjustableFeetChanged },
            model: {
              value: _vm.adjustableFeet,
              callback: function($$v) {
                _vm.adjustableFeet = $$v
              },
              expression: "adjustableFeet"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "filter-options dropdown" }, [
      _c(
        "h6",
        {
          staticClass: "heading",
          attrs: {
            "data-toggle": "collapse",
            "data-target": "#refrigerator-capacity-filter",
            role: "button",
            "aria-expanded": "true",
            "aria-controls": "refrigerator-capacity-filter"
          }
        },
        [_vm._v(" Refrigerator gross capacity ")]
      ),
      _c(
        "div",
        {
          staticClass: "wrapper collapse show",
          attrs: { id: "refrigerator-capacity-filter" }
        },
        [
          _c("VueMultiselect", {
            attrs: {
              options: _vm.refrigeratorCapacities,
              multiple: true,
              "close-on-select": true,
              "open-direction": "bottom",
              placeholder: "Select refrigerator capacities",
              searchable: false,
              selectLabel: "",
              deselectLabel: ""
            },
            on: { input: _vm.refrigeratorCapacityChanged },
            model: {
              value: _vm.refrigeratorCapacity,
              callback: function($$v) {
                _vm.refrigeratorCapacity = $$v
              },
              expression: "refrigeratorCapacity"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "filter-options dropdown" }, [
      _c(
        "h6",
        {
          staticClass: "heading",
          attrs: {
            "data-toggle": "collapse",
            "data-target": "#compartment-filter",
            role: "button",
            "aria-expanded": "true",
            "aria-controls": "compartment-filter"
          }
        },
        [_vm._v(" List compartment ")]
      ),
      _c(
        "div",
        {
          staticClass: "wrapper collapse show",
          attrs: { id: "compartment-filter" }
        },
        [
          _c("VueMultiselect", {
            attrs: {
              options: _vm.compartments,
              multiple: true,
              "close-on-select": true,
              "open-direction": "bottom",
              placeholder: "Select compartments",
              searchable: false,
              selectLabel: "",
              deselectLabel: ""
            },
            on: { input: _vm.compartmentChanged },
            model: {
              value: _vm.compartment,
              callback: function($$v) {
                _vm.compartment = $$v
              },
              expression: "compartment"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "filter-options dropdown" }, [
      _c(
        "h6",
        {
          staticClass: "heading",
          attrs: {
            "data-toggle": "collapse",
            "data-target": "#shelves-filter",
            role: "button",
            "aria-expanded": "true",
            "aria-controls": "shelves-filter"
          }
        },
        [_vm._v(" Number of shelves in fridge department ")]
      ),
      _c(
        "div",
        {
          staticClass: "wrapper collapse show",
          attrs: { id: "shelves-filter" }
        },
        [
          _c("VueMultiselect", {
            attrs: {
              options: _vm.shelvesInFridge,
              multiple: true,
              "close-on-select": true,
              "open-direction": "bottom",
              placeholder: "Select number of shelves",
              searchable: false,
              selectLabel: "",
              deselectLabel: ""
            },
            on: { input: _vm.shelveInFridgeChanged },
            model: {
              value: _vm.shelveInFridge,
              callback: function($$v) {
                _vm.shelveInFridge = $$v
              },
              expression: "shelveInFridge"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "filter-options dropdown" }, [
      _c(
        "h6",
        {
          staticClass: "heading",
          attrs: {
            "data-toggle": "collapse",
            "data-target": "#highlight-filter",
            role: "button",
            "aria-expanded": "true",
            "aria-controls": "highlight-filter"
          }
        },
        [_vm._v(" Highlights ")]
      ),
      _c(
        "div",
        {
          staticClass: "wrapper collapse show",
          attrs: { id: "highlight-filter" }
        },
        [
          _c("VueMultiselect", {
            attrs: {
              options: _vm.highlights,
              multiple: true,
              "close-on-select": true,
              "open-direction": "bottom",
              placeholder: "Select highlights",
              searchable: false,
              selectLabel: "",
              deselectLabel: ""
            },
            on: { input: _vm.highlightChanged },
            model: {
              value: _vm.highlight,
              callback: function($$v) {
                _vm.highlight = $$v
              },
              expression: "highlight"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "filter-options dropdown" }, [
      _c(
        "h6",
        {
          staticClass: "heading",
          attrs: {
            "data-toggle": "collapse",
            "data-target": "#water-dispenser-filter",
            role: "button",
            "aria-expanded": "true",
            "aria-controls": "water-dispenser-filter"
          }
        },
        [_vm._v(" Water dispenser ")]
      ),
      _c(
        "div",
        {
          staticClass: "wrapper collapse show",
          attrs: { id: "water-dispenser-filter" }
        },
        [
          _c("VueMultiselect", {
            attrs: {
              options: _vm.waterDispensers,
              multiple: true,
              "close-on-select": true,
              "open-direction": "bottom",
              placeholder: "Select water dispensers",
              searchable: false,
              selectLabel: "",
              deselectLabel: ""
            },
            on: { input: _vm.waterDispenserChanged },
            model: {
              value: _vm.waterDispenser,
              callback: function($$v) {
                _vm.waterDispenser = $$v
              },
              expression: "waterDispenser"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("span", [_vm._v("to")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }